@import '../../../styles/base/layout';

.premium_badge {
  display: inline-block;
  border-radius: 10px;
  height: 18px;
  padding: 4px 5px 4px;
  margin: 8px 10px;
  text-align: center;
  color: var(--font-color-light);
  &.black {
    background-image: linear-gradient(#000, rgba(0, 0, 0, 0.5));
  }
  &.purpleBg {
    background: linear-gradient(180deg, #9318E1 0%, #4E097B 100%);
  }
  &.cyanBg {
    background: linear-gradient(180deg, #4DC3BA 0%, #008E84 100%);
  }
  &.orangeBg {
    background: linear-gradient(180deg, #F4A419 0%, #E78819 100%);
  }
  &.redBg {
    background: linear-gradient(180deg, #FF7272 0%, #D60000 100%);
  }
  &.grayBg {
    background: linear-gradient(180deg, #A8A8A8 0%, #4F4F4F 100%);
  }
  &.greenBg {
    background: linear-gradient(180deg, #4DBD58 0%, #159322 100%);
  }
  .wrapper {
    display: flex;
    height: 10px;
    .premium_badge__icon {
      width: 10px;
      height: 10px;
      background: rgba(0,0,0,0);
      display: inline-block;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .premium_badge__title {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      max-height: 10px;
      display: inline-block;
      margin-left: 4px;
    }
  }
}
/* purgecsignore */