@import '../../../styles/base/layout';


.features_list {
  margin: 10px auto 16px;
  padding: 0 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  &.small_row {
    margin: 16px auto;
    justify-content: space-around;
    width: 70%;
    flex-wrap: wrap;
    .feature {
      margin-bottom: 16px;
    }
  }
  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    .feature_image {
      flex-basis: 0;
      flex-grow: 1;
      .feature_image_wrapper {
        width: 40px;
        height: 40px;
        border-radius: 30px;
        background-color: #F1F1FC;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
        img {
          max-width: 32px;
          max-height: 32px;
        }
      }
    }
    .feature_text {
      margin-top: 6px;
      flex-basis: 0;
      flex-grow: 3;
      font-size: 10px;
      color:#686A79;
      .text_wrapper {
        max-width: 80px;
      }
    }
  }
}
