@import '../../../styles/base/layout';

.not_included {
  margin-top: 5px;
  margin-bottom: 16px;
  margin-left: 16px;
  p {
    font-size: 14px;
    color: #585A6B;
    font-weight: 700;
    margin-bottom: 12px;
    margin-left: -8px;
  }
  .data_list {
    font-size: 14px;
    color: #686A79;
    margin-bottom: 16px;
    position: relative;
    padding-left: 20px;
    li {
      position: relative;
      margin-bottom: 6px;
      &:before {
        position: absolute;
        display:inline-block;
        content:"";
        font-size:5px;
        line-height: 12px;
        color:#fff;
        width:12px;
        height:12px;
        border-radius: 50%;
        background: url("../../../public/static/images/PremiumCards/cross.svg") no-repeat center;
    		background-size: cover;
        vertical-align: middle;
        left: -28px;
      }
    }
  }
}
