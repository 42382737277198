@import "../../base/layout";

.accordion:not(#a) {
     padding:0;
     border-radius:8px;
     background: var(--theme-color-white);
     box-shadow:0 2px 1px  rgba(151,151,151,0.2);
     &:before {
         content:none;
     }
    .accordion__summary {
        min-height: auto;
        padding:17px 16px;
        @media (min-width: map-get($grid-breakpoints,md)) {
            padding: 30px 40px;
        }
        &.accordion_expanded {
          border-bottom:1px solid rgba(151,151,151,0.2);
        }
        .accordion_content {
            margin:0;
            align-items: center;
        }
        .accordion__summary_status_text {
            display:none;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                display:inline-block;
                font-size:20px;
                font-family: var(--font-bold);
            }
        }
        .accordion__summary_status_icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width:16px;
            height:16px;
            border-radius: 50%;
            background: #00d65f;
            &:before {
                display: inline-block;
                font-family: "Icon";
                content:"\e903";
                font-weight: bold;
                font-size:7px;
                color:#fff;
            }
            @media (min-width: map-get($grid-breakpoints,md)) {
                width:24px;
                height:24px;
                &:before {
                    font-size:10px;
                }
            }

        }
        .accordion_title_subtitle{
            display: flex;
            flex-direction: column;

            .accordion__summary_subtitle{
                padding-top: 8px;
                font-size: 14px;
                font-family:var(--font-regular)
            }
        }
    }
    .accordion__details {
        padding:17px 16px;
        @media (min-width: map-get($grid-breakpoints,md)) {
            padding: 30px 40px;
        }
    }
    .accordion__summary_title {
        font-family: var(--font-bold);
        font-size: 14px;
        color:#252525;
        line-height: 1.4;
        @media (min-width: map-get($grid-breakpoints,md)) {
            font-size: 20px;
        }
    }
    .accordion__summary_right {
        margin-left:auto;
        display:inline-flex;
        align-items: center;
        padding-left:10px;
        span + span {
            margin-left:19px;
        }
        .accordion__summary_status_arrow {
           &:before {
               font-family: "Icon";
               content:"\e900";
               font-weight: bold;
               font-size: 12px;
               @media (min-width: map-get($grid-breakpoints,md)) {
                   font-size: 16px;
               }
           }
        }
        @media (min-width: map-get($grid-breakpoints,md)) {
            .accordion__summary_status_arrow {
                margin-left:60px;
            }
        }
    }
    & + .accordion {
        margin-top:32px;
        @media (min-width: map-get($grid-breakpoints,md)) {
            margin-top:48px;
        }
    }
    &[data-simple='true'] {
      border-radius:8px;
      .accordion__summary {
        @media (min-width: map-get($grid-breakpoints, md)) {
          padding: 23px 41px;
        }
      }
      .accordion__details {
          flex-direction: column;
          padding: 23px 16px 32px 16px;
        //padding-right: 52px;
        line-height: 1.5;
        font-size: 12px;
        //text-align: justify;
        @media (min-width: map-get($grid-breakpoints, md)) {
          padding:39px 37px 46px 37px;
          font-size: 16px;
          line-height: 1.75;
          flex-direction: row;
          align-items: center;
        }
      }
      .accordion__summary_title {

        margin-bottom: 0;
        padding: 0;
        @media (min-width: map-get($grid-breakpoints,md)) {
            font-size:20px;
        }
      }
    }
  &.no_decoration {
    border: none;
    box-shadow: none;
    .accordion__summary {
      border: none;
    }
  }
  &.homecare_accordian {
    background: #f8f8f3;
    margin-top: 12px;
    margin-bottom: 0;
    .accordion__details {
      padding-top: 10px;
    }
  }

  &.rate_card_group {
    .accordion__summary {
      padding: 0 8px;
      background-color: var(--primary-color-green);
      color: #fff;
      .accordion_content {
        color: #fff;
      }
      &.accordion_expanded {
        .accordion_content {
          padding: 0;
          margin: 12px auto;
        }
      }
    }
    .accordion__details {
      padding: 0;
    }
    .accordion__summary_title {
      color: #fff;
      font-size: 12px;
      @media(min-width: map-get($grid-breakpoints, lg)) {
        font-size: 16px;
      }
      font-weight: 500;
    }
  }
}
