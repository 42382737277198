@import "../../base/layout";

.add_cart_button_holder{
  font-size: 14px;
  font-weight: bold !important;
  text-align: center;
  @media (max-width: 450px) and (min-width: 360px) {
    font-size: 10.5px;
  }
  .has_quantity{
    background: var(--theme-color-white);
    display: inline-block;
    border: 1px solid var(--secondary-color-green);
    border-radius: 3px;
    min-width: 50px;
    position: relative;
    padding: 7px 25px;
    text-align: center;
    a{
      padding: 5px;
      background: var(--secondary-color-green);
      color: var(--theme-color-white);
      cursor: pointer;
      display: inline-block;
      position: absolute;
      left: -1px;
      top: -1px;
      bottom: -1px;
      border-radius: 3px;
      @media (min-width: 525px) {
        padding: 5px;
      }
      &:last-child{
        left: auto;
        right: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:first-child{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      i{
        position: relative;
        top: calc(50% - 7px);
      }
    }
    span.quantity{
      cursor: text;
      background: var(--theme-color-white);
      color: #585A6B;
      display: block;
      @media (min-width: 525px) {
      }
    }
  }
  .add_btn{
    color: var(--secondary-color-green);
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding: 7px 15px;
    border: 1px solid;
    background: var(--theme-color-white);
    border-radius: 3px;
    position: relative;
    sup{
      position: relative;
      bottom: 5px;
      right: -1px;
    }
  }
}

.disable_button{
  pointer-events: none;
  opacity: 0.2;
}
