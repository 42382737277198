@import '../../../styles/base/layout';

.note {
  position: relative;
  margin: 16px;
  border-radius: 8px;
  padding: 16px;
  background-color: #F1F1FC;
  .badge {
    position: absolute;
    text-align: center;
    display: inline-block;
    max-height: 18px;
    width: 40px;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    padding: 4px 0;
    border-radius: 9px;
    background-color: #fda000;
    top: -9px;
  }
  p {
    font-size: 12px;
    color: #686A79;
    line-height: 1.3;
  }
}
