@import "../../base/layout";
@import "../../utilities/utilities";

.app_dropdown__labelname{
  display: block;
  margin-bottom: 5px;
  font-family: var(--font-regular);
  font-size: 14px;
  em {
    color:var(--font-color-error);
    font-family: var(--font-bold);
}
}

.app_dropdown {
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: var(--border-radius-sm);
  border: solid 1px #c0c0c0;
  background-color: #ffffff;
  &[data-valid="false"] {
    border-color: var(--font-color-error);
  }
  &[data-generic="true"]:after {
    font-family: "Icon";
    position: absolute;
    content: "\e900";
    right: 10px;
    @media (max-width: map-get($grid-breakpoints,sm)) {
      right: 5px;
    }
    top: 50%;
    color: #535353;
    opacity: 1;
    transform: translateY(-50%);
    font-weight: bold;
    transition: transform 300ms ease-in;
    // padding-right: 15px;
  }
  &[data-home="true"] {
    padding-left: 41px;
  }
  &[data-home="true"]:before {
    position: absolute;
    font-family: "Icon";
    content: "\e90d";
    font-size: 16px;
    color: #575f65;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    color: #575f65;
    font-weight: bold;
    opacity: 0.7;
  }
  &[data-open="true"]{
    outline:none;
    border-color: var(--primary-color-orange) !important;
  }
  &.protection_plans__modal {
    width: 82px;
    height: 38px;
    font-size: 12px;
    margin-top:14px;
    .app_dropdown__label {
      padding: 12px;
    }
    .app_dropdown__menu {
      .dropdown__menu_item {
        font-size: 12px;
        padding: 12px;
      }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      width: 120px;
      font-size: 14px;
      .app_dropdown__menu {
        .dropdown__menu_item {
          font-size: 14px;
        }
      }
    }
  }
  &:focus {
    outline: 0;
  }
  & > .app_dropdown__label {
    display: block;
    font-size: 14px;
    padding: 17px 37px 17px 16px;
    font-family: var(--font-medium);
    text-align: left;
    color: #000;
    letter-spacing: 0.23px;
    line-height: 1;
    @extend %ellipsis;
    padding-right: 37px;
    &.afterSelection{
      font-family: var(--font-bold);
    }
    &.beforeSelection{
      color: var(--clp-text-4, #565F65);
      font-family: var(--font-regular) !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    span{
      color: var(--clp-text-4, #565F65);
      font-family: var(--font-regular);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      padding: 18px 43px 16px 16px;
      padding-right: 43px;
    }
  }
  // &.search > .search {
  //     display:none;
  // }
  &.search > .text {
    position: absolute;
    top: 0;
  }
  &.search > .text.filtered {
    display: none;
  }
  .app_dropdown__search {
    pointer-events: none;
    //user-select: none;
    width: 100%;
    padding: 16px 37px 16px 16px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    letter-spacing: 0.23px;
    line-height: 1;
    font-family: var(--font-medium);
    background: transparent;
    opacity: 0;
    z-index: -1;
    padding-right: 45px;
    @media (min-width: map-get($grid-breakpoints,lg)) {
      padding: 17px 30px 17px 16px;
      padding-right: 43px;
      transform: translateY(1px);
    }
    &:focus {
      outline: 0;
      border: none;
    }
  }
  .app_dropdown__menu {
    position: absolute;
    width: calc(100% + 4px);
    bottom: -20px;
    left: -2px;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transform: translateY(100%);
    transition: all 200ms ease-in;
    background-color: #fff;
    border-radius: var(--border-radius-sm);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.07);
    max-height: 212px;
    overflow-y: auto;
    z-index: -1;
    &::-webkit-scrollbar  {
      width:8px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb  {
      background: #757575;
      border-radius:4px;
    }
    &[data-position="top"] {
      bottom:auto;
      top:-10px;
      transform: translateY(-100%);
    }
    .dropdown__menu_item {
      padding: 27px;
      text-align: left;
      cursor: pointer;

      span {
        pointer-events: none;
        font-family: var(--font-medium);
        font-size: 16px;
        color: #575f65;
        line-height: 1.3;
      }
      small {
        font-size: 12px;
        color: var(--primary-color-green);
        font-family: var(--font-bold);
        padding-left: 10px;
      }
      &:hover, &:focus,
      &[data-selected="true"] {
        background-color: rgb(240, 240, 240);
      }
      &:focus {
        outline: none;
        background: var(--primary-color-green);
        span , small {
          color:#f6f6f6
        }
      }
      & + .dropdown__menu_item {
        border-top: 1px solid #cdd3d9;
      }
    }
  }

  &.summary_table__edit_fields {
    max-height: 30px;
    border-width: 2px;
    .app_dropdown__label {
      padding-top: 9px;
      padding-bottom: 9px;
      font-size: 12px;
    }
    .app_dropdown__menu {
      width: calc(100% + 100px);
      left: auto;
      right: 0;
      .dropdown__menu_item {
        padding-top: 9px;
        padding-bottom: 9px;
        span {
          font-size: 12px;
        }
      }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      max-height: 38px;
      width: 266px;
      right: auto;
      left: 0;
      .app_dropdown__label {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 14px;
      }

      .app_dropdown__menu {
        width: 100%;
        .dropdown__menu_item {
          padding-top: 12px;
          padding-bottom: 12px;
          span {
            font-size: 14px;
          }
        }
        .app_dropdown__menu {
          width: 100%;
          .dropdown__menu_item {
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }
      }
    }
  }

  &.slot_selection_timeslot{
    height: 48px;
    width: 100%;
    border: 1px solid #000000;
    &:focus:not(.dropdown__disabled) {
     border-color:var(--primary-color-orange);
     color:#565F65
    }
    .app_dropdown__label {
      padding: 15px 37px 15px 40px;
      font-size: 16px;
      line-height: 16px;
      font-family: var(--font-regular);
      @media (max-width: map-get($grid-breakpoints,sm)) {
        padding-left: 22px;
        padding-right:10px ;
        font-size: 12px;
      }
      @media (max-width: 359px) {
        padding-left: 30px;
        padding-right:10px ;
        font-size: 12px;
      }
    }
    .app_dropdown__menu {
      width: calc(100%+100px);
      left: -2px;
      right: auto;
      z-index: 2;
      background: #FFFFFF;
      .dropdown__menu_item {
        padding: 16px 10px 15px;
        span {
          font-size: 14px;
          line-height: 16px;
          font-family: var(--font-regular);
          color: #3D3D3D;
        }
      }
    }

    // @media (min-width: map-get($grid-breakpoints,lg)) {
    //   right: auto;
    //   left: 0;
    //   .app_dropdown__menu {
    //     width: 100%;
    //     .dropdown__menu_item {
    //       padding-top: 12px;
    //       padding-bottom: 12px;
    //       span {
    //         font-size: 14px;
    //       }
    //     }
    //     .app_dropdown__menu {
    //       width: 100%;
    //       .dropdown__menu_item {
    //         padding-top: 12px;
    //         padding-bottom: 12px;
    //       }
    //     }
    //   }
    // }
  }

  &.dropdown_not_selected{
    border: 1px solid #9394A0;
    background-color: #FFFFFF !important;
    color: #565f65 !important;
    .app_dropdown__label{
      color: #565f65 !important;
    }
    &:focus {
      font-family: var(--font-regular);
      border:1px solid var(--primary-color-orange);
      color:#565F65;
      opacity: 1;
     }
  }
}

.dropdown__open:after , .dropdown__custom:focus:after {
  transform: translateY(-50%) rotate(-180deg) !important;
}

.dropdown__custom:focus .app_dropdown__menu, .dropdown__custom:focus-within .app_dropdown__menu , .dropdown__open .app_dropdown__menu {
  opacity: 1;
  bottom: -8px;
  pointer-events: all;
  z-index:1;
}

.dropdown__open[data-search="true"] .app_dropdown__label , .dropdown__custom[data-search="true"]:focus .app_dropdown__label , .dropdown__custom[data-search="true"]:focus-within .app_dropdown__label {
  display: none;
}

.dropdown__open[data-search="true"] .app_dropdown__search , .dropdown__custom[data-search="true"]:focus .app_dropdown__search , .dropdown__custom[data-search="true"]:focus-within .app_dropdown__search {
  display: block;
  pointer-events: all;
  opacity: 1;
  z-index: 1;
}

.dropdown__no_result {
  display: block;
  padding: 16px 30px 16px 16px;
  font-size: 20px;
  font-family: var(--font-bold);
  text-align: center;
}

@media (max-width: map-get($grid-breakpoints,sm)) {
  .app_dropdown__mobile {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    //padding:24px;
    z-index: -2;
    user-select: none;
    opacity: 0;
    pointer-events: none;
    background: var(--theme-color-white);
    transform: translateY(20px);
    transition: opacity 150ms cubic-bezier(0.35, 0.96, 0, 1.21), transform 150ms cubic-bezier(0.35, 0.96, 0, 1.21) 20ms;
    &[data-is-open="true"] , .app_dropdown__mobile:focus , .app_dropdown__mobile:focus-within {
      z-index: 6;
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
    }
    ul {
      max-height: calc(100% - 84px);
      overflow-y: auto;
      padding-left: 24px;
      padding-right: 19px;
    }
    .dropdown__menu_item {
      padding-top: 24px;
      padding-bottom: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 14px;
        line-height: 1.71;
        color: #575f65;
        font-family: var(--font-medium);
        letter-spacing: 0.23px;
      }
      small {
        font-size: 12px;
        color: var(--primary-color-green);
        font-family: var(--font-bold);
        padding-left: 10px;
      }
      small,
      span {
        pointer-events: none;
      }
      & + .dropdown__menu_item {
        border-top: 1px solid #cdd3d9;
      }
    }
    .device_type{
      text-align: right;
    }
    .app_dropdown__mobile_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: calc(100% - 48px);
      height: 56px;
      margin: 14px auto;
      a {
        display: block;
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
        text-decoration: none;

        &:before {
          display: inline-block;
          font-family: "Icon";
          content: "\e901";
          font-size: 18px;
          color: #7c7a7a;
          vertical-align: middle;
          font-weight: lighter;
        }
      }
      input {
        width: calc(100% - 35px);
        flex: 0 0 calc(100% - 35px);
        max-width: calc(100% - 35px);
        height: 100%;
        padding-right: 12px;
        padding-left: 12px;
        border-radius: var(--border-radius-sm);
        font-size: 14px;
        font-family: var(--font-bold);
        outline: 0;
        border: 2px solid #cdd3d9;
        &:focus {
          border-color: #fdb913;
        }
        &::placeholder {
          font-family: var(--font-regular);
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints,sm)) {
  .app_dropdown__mobile {
    .dropdown__menu_item_mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 32px !important;
    }
  }
}

.textfield__error {
  position: absolute;
  bottom: -2px;
  left: 2px;
  font-size: 10px;
  font-family: var(--font-semibold);
  color: var(--font-color-error);
  transform: translateY(100%);
  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 12px;
  }
}

.dropdown__disabled{
  background-color: #F8F8F3 !important;
  opacity: 0.6 !important;
  border:1px solid #9394A0 !important;
}

.placeholder{
  color: var(--clp-text-4, #565F65);
  font-family: var(--font-regular) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.otherField {
  color: var(--elan-tertiary-1, #008cf0) !important;
  font-family: var(--font-semi-bold) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}