@import '../../../styles/base/layout';

.progress_bar {
  margin-top: 0;
  margin-bottom: 12px;
  .progress_wrapper {
    width: 100%;
    height: 4px;
    padding: 0;
    margin: 0;
    .progress {
      max-width: 100%;
      padding: 0;
      margin: 0;
      height: 4px;
      background-color: #3E4054;
      border-radius: 0 0 0 4px;
      &.max_value {
        border-radius: 0 0 4px 4px;
      }
    }
  }
}
