@import '../../../styles/base/layout';

.offer_details {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 12px 0 8px;
  .offer_icon {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 16px;
    max-height: 16px;
    margin-right: 4px;
    img {
      width: 100%;
    }
  }
  .offer_text {
    flex-basis: 0;
    flex-grow: 4;
    font-size: 12px;
    color: #686A79;
    line-height: 1.2;
    span {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.7);
    }
  }
}
