@import "../../base/layout";
@import "../../utilities/utilities";


.__openToast{
  display: flex;
  position: absolute;
  background: #DCFEE0;
  border: 1px solid #4DBD58;
  z-index: 2;
  font-size: 14px;
  line-height: 16px;
  color: #4DBD58;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 13px 40px 14px 19px;
  margin-right: 10px;
  margin-left: 10px;
  transform: translateX(0);
  font-family: var(--font-semi-bold);
  transition:opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1),transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  column-gap: 8px;
  align-items: center;
  @media (min-width: map-get($grid-breakpoints,lg)) {
      position: fixed;
      top: 100px;
      width: 420px;
      right: 30px;
  }
  @media (max-width: map-get($grid-breakpoints,md)) {
    right: 0;
}
  @media (max-width: map-get($grid-breakpoints,sm)) {
      top: 80px;
      margin: auto;
      position:fixed;
      left: 0;
      box-shadow: 2px 0 4px 0 #00000019;
      margin-left: 10px;
      margin-right: 10px;
  }
  
}

.app_popover {
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index:-1;
    user-select: none;
    pointer-events: none;
    opacity:0;
    transition: all 300ms ease-in 220ms;
    @media (min-width: map-get($grid-breakpoints, md)) {
        top:80px;
        transition: all 50ms ease-in-out;
    }
    &[data-open="true"] {
        z-index:1000;
        pointer-events: all;
        user-select:initial;
        opacity:1;
        transition-delay:0ms;
    }
    &[data-priority="1"] {
        z-index:8;
    }
    &.blocker_popover{
        z-index:4;
        top: 56px !important;
        @media (min-width: map-get($grid-breakpoints,sm)) {
            top: 80px !important;
        }
        .app_popover__el{
            .plain{
                @media (min-width: map-get($grid-breakpoints, md)) {
                    margin-top: 100px !important;
                    border-radius: 10px !important;
                }
            }
        }
    }
    .app_popover__overlay {
        position: absolute;
        background: rgba(0, 0, 0, 0.8);
        width:100%;
        height:100%;
        top:0;
        left:0;
        z-index:0;
        opacity:0;
        transition:opacity 200ms ease-in 100ms;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            transition:opacity 100ms ease-in;
        }

    }
    .app_popover__el {
        position: relative;
        height:100%;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            height: auto;
        }
    }
    .app_popover__box {
        position: absolute;
        background-color: #fff;
        width:100%;
        height:80vh;
        z-index:2;
        left:0;
        bottom:0;
        border-radius:12px 12px 0 0;
        transform:translateY(100%);
        transition: transform 200ms linear 120ms;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // @media (min-height: 570px) {
        //     height:512px;
        // }
        @media (min-width: map-get($grid-breakpoints, sm)) {
            display: block;
            width:360px;
            height: auto;
            bottom:auto;
            left:auto;
            top:15px;
            right:0;
            border-radius: 4px;
            transform: translateY(0);
            transition: transform 100ms linear;
        }

    }
    .app_popover__box .app_popover__header {

        padding:20px 32px;
        border-bottom: solid 1px rgba(151,151,151,0.3);
        @media (min-width: map-get($grid-breakpoints, sm)) {
            padding:16px;
            position: relative;
        }
        .popover_icon {
            display: inline-block;
            margin-right: 16px;
            vertical-align: middle;
            &:before {
                display: inline-block;
                font-family:"Icon";
                content:"\e902";
                font-size: 16px;
                color:var(--primary-color-green);
            }
            @media (min-width: map-get($grid-breakpoints, sm)) {
                &:before {
                    font-size:22px;
                }
            }
        }
        h5 {


            font-size:16px;
            font-family: var(--font-medium);
            color:#000;
            letter-spacing:0.3px;
            @media (min-width: map-get($grid-breakpoints, sm)) {
                font-size: 14px;
                line-height: 1;
            }
        }
        .popover_icon , h5 {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .app_popover__close_btn {
        position:absolute;
        display: inline-block;
        width:20px;
        height:20px;
        background-color: #fff;
        right:12px;
        top:-12px;
        transform:translateY(-100%);
        border-radius: 50%;
        text-align: center;
        font-size:0;
        &:before {
            display: inline-block;
            font-family: "Icon";
            content:"\e904";
            font-size:5px;
            color:#575f65;
            line-height:20px;
            font-weight: bold;
        }
        @media (min-width: map-get($grid-breakpoints, sm)) {
            width:12px;
            height:12px;
            top:50%;
            right:24px;
            transform:translateY(-50%);
            background:transparent;
            &:before {
                font-size: 10px;
            }
        }
    }
    &[data-open="true"]  {
        .app_popover__overlay {
            opacity:1;
        }
        .app_popover__box {
            transform:translateY(0);
        }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        &[data-medium="true"] {
            .app_popover__box {
                width:680px;
            }
        }
        &[data-large="true"] {
            .app_popover__box {
                width:758px;
            }
        }
        &[data-xlarge="true"] {
            .app_popover__box {
                width: 1000px;
            }
        }
    }

}


.app_popover__body {
    margin-bottom: auto;
    padding:16px 10px;
    height: calc(100% - 136px);
    flex:0 0 calc(100% - 136px);
    // @media (min-width: 360px) and (max-height: 640px) {

    // }
    @media (min-width: map-get($grid-breakpoints, sm)) {
        min-height: 187px;
        max-height: 187px;
        margin-bottom:0;
    }
    @media(min-width: map-get($grid-breakpoints, lg)) {
        max-height:initial;
    }
}
.app_popover_popover_body {
    overflow-y: auto;
}
.app_popover__action {

    @media (min-width: map-get($grid-breakpoints, sm)) {
        display: flex;
        border-top: solid 1px rgba(151,151,151,0.3);
        align-items: center;
        justify-content: space-between;
    }


    a {
        font-size: 14px;
        padding-top:13px;
        padding-bottom: 13px;
        align-self:center;
    }
    .popover__add_plan {
        padding-left: 16px;
        padding-right: 16px;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            display: none;
        }
    }
    .popover__checkout {
        padding-left: 42px;
        padding-right: 42px;
    }
}

.app_popover__action_btns {
    border-top: solid 1px rgba(151,151,151,0.3);
    padding: 16px;
    @extend %flex;
    @extend %justify-space-between;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        border-top:none;
        order:-1;
    }
}

.app_popover__summary {
    @extend %flex;
    @extend %justify-space-between;
    padding:12px;
    padding-left: 0;
    @media(min-width: map-get($grid-breakpoints, lg)) {
    margin-left:auto;
    padding-left: 12px;
    }
    span {
        color:#000;
        font-family: var(--font-bold);
        font-size: 20px;
        letter-spacing: 0.3px;
    }
    @media(min-width: map-get($grid-breakpoints, lg)) {
        span {
            font-size: 16px;
        }
    }

}


.app_popover[data-widget="false"] {
    .app_popover__body {

        min-height: calc(100% - 140px);
        max-height: calc(100% - 140px);
        overflow-y: auto;

    }
}


.cart_list {

    .category_heading {
      margin-bottom: 12px;
      @media(min-width: map-get($grid-breakpoints, lg)) {
        margin: 0;
      }
    }
    .cart_list__item {
        // @extend %flex;
        @extend %align-center;
        margin-bottom: 10px;
        .list_item_image {
            img {
                display: block;
                width:100%;
                height: auto;
            }
            @media (min-width: map-get($grid-breakpoints, sm)) {
                 width:48px;
                 flex:0 0 48px;
            }
        }
        .vertical_center {
          display: flex;
          align-items: center;
        }
        .list_item__name {
            display: block;
            font-family: var(--font-medium);
            line-height: 1.43;
            color:#000;
            font-size: 14px;
            &.secondary_text {
              color: var(--font-color-content-light)
            }
            @media (min-width: map-get($grid-breakpoints, sm)) {
                font-size: 14px;
           }
        }
        .list_item__price {
            font-family: var(--font-bold);
            width:100%;
            text-align: right;
            @media (min-width: map-get($grid-breakpoints, sm)) {
                font-size: 14px;
           }
        }
        & + .cart_list__item {
            margin-top:10px;
        }

        &.insta_item {
          margin-bottom: 0;
        }
        &.insta_subitem {
          margin-top: 0;
        }
    }
    .cart_list_row {
        align-items: center;
        margin:0;
    }
    .cart_list_row .cart_list_col  {
        padding:0 8px;
        flex-direction: column;
        align-items: flex-start;
        &:last-child {
            text-align: left;
        }
    }
}


.app_popover_modal {


    height:100%;

    .app_popover__box {

        padding:24px 16px 0 16px;
        height: auto;
        &.plain{
            padding: 0;
        }
        &.full_screen_popup{
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
        @media (min-width: map-get($grid-breakpoints, sm)) {
            position: relative;
            //min-height:650px;
            &:before , &:after {
                position: absolute;
                content:"";
                background: red;
                overflow: hidden;

            }
            &:before {
                width:123px;
                height:106px;
                left:0;
                bottom:0;
                background: url(../../../public/static/images/modal/modal-shape-1.svg) no-repeat center;
                background-size: cover;
                z-index:0;

            }
            &:after {
                width:72px;
                height:132px;
                right:0;
                top:0;
                background: url(../../../public/static/images/modal/modal-shape-2.svg) no-repeat center;
                background-size: cover;
                border-top-right-radius: 25px;
                z-index:0;
            }
            &.plain{
                padding: 0;
                &:before, &:after {
                    width: 0;
                    height: 0;
                    background: #fff !important;
                }
            }
        }
    }
    .app_popover__close_btn {
        text-decoration: none;
        text-align: center;
        font-size:0;
        &:before {
            display: inline-block;
            font-family:"Icon";
            content:"\e904";
            font-size:5px;
            color:#575f65;
            font-weight: bold;
            line-height: 20px;
            @media (min-width: map-get($grid-breakpoints, sm)) {
                font-size: 10px;
                line-height:40px;
            }

        }
    }
    .app_popover__body {
        height:80vh;
        overflow-y:auto;
        overflow-x: hidden;
        @media (min-height:570px) {
            max-height:initial;
            padding:0;
            height:100%;
            overflow:visible;
        }

    }
    @media (min-width: map-get($grid-breakpoints, sm)) {
        top:0;
        overflow-y: auto;
        .app_popover__el {
            height:100%;
            display: flex;
        }
        .app_popover__overlay {
            height:inherit;
        }
        .app_popover__box {
            width:560px;
            border-radius: 24px;
            left:auto;
            bottom:auto;
            margin:auto;
            transform: translateY(80px);
            transition:transform 300ms cubic-bezier(0.35, 0.96, 0, 1.21);
            padding:40px 64px 46px 40px;
            @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
                width:620px;
            }

        }
        .app_popover__close_btn {
            top:0;
            right:-24px;
            width:40px;
            height:40px;
            background: #fff;
            opacity:0;
            transform:translateX(100%) translateY(40px);
            transition:all 300ms cubic-bezier(0.35, 0.96, 0, 1.21) 150ms;
            text-align: center;

        }
    }
    &[data-open="true"] {
        .app_popover__close_btn {
            @media (min-width: map-get($grid-breakpoints, sm)) {
                opacity:1;
                transform:translateX(100%) translateY(0);
            }
        }
        .app_popover__box {
            transform:translateY(0);
            @media (min-width: map-get($grid-breakpoints, sm)) {
                transform: translate(0);
            }
        }
    }
}


.empty_cart {
    position: relative;
    padding: 33px;
    text-align: center;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        display: block;
    }
    ._icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width:130px;
        height:130px;
        border-radius: 50%;
        background:rgba(239,239,232,0.4);
        margin-bottom:22px;
        margin-left:auto;
        margin-right:auto;
        &:before {
            display:inline-block;
            background: red;
            content:"";
            width:80px;
            height:80px;
            background: url(../../../public/static/images/empty-cart.svg) no-repeat center;
            background-size: contain;
            vertical-align: middle;
        }
    }
    ._close {
        position:absolute;
        top:-32px;
        right:12px;
        display:block;
        width:20px;
        height:20px;
        background:#fff;
        border-radius: 50%;
        font-size:0;

        &:after {
            display: inline-block;
            font-family:"Icon";
            content:"\e904";
            font-size:5px;
            color:#575f65;
            line-height:20px;
            font-weight: bold;
        }
        @media (min-width: map-get($grid-breakpoints, sm)) {
            top:24px;
            right:24px;
            &:after {
                font-size:10px;
            }

        }
    }
    h4 {
        font-size:20px;
        margin-bottom:10px;
    }
    span {
        display: block;
        line-height: 1.43;
        font-family: var(--font-regular);
        color:#3d3d3d;
        font-size:14px;
    }
}


.cart_widget {
    .app_popover__el {
        .app_popover__box .app_popover__body {
            @media (min-width: map-get($grid-breakpoints, lg)) {
                max-height:300px;
            }
        }
    }
}


.app_popover__modal_body {
    padding-bottom: 0;
   @media (max-height:650px)  {
       max-height: calc(100vh - 90px);
       overflow-y: auto;
   }
}

.render_modal{
    @media (max-width: map-get($grid-breakpoints, sm)) {
        position: absolute;
        bottom: 50px !important;
        // top: 194px !important;   
        // height: 70vh !important;
        // width: 314px !important;
        width: 90% !important;
        // margin: 0px 24px;
        border-radius: 12px !important;
        left: 50% !important;
        // right: 50% !important;
        transform: translate(-50%, 0%) !important;
        background-color: #000 !important;
        // height: calc(100vh - 100px) !important;
    }
}