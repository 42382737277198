@import '../../../styles/base/layout';

.premium_drawer {
  padding: 0 8px;
  .banner {
    max-width: 100%;
    margin-bottom: 10px;
    img {
      width: 100%;
    }
  }

  @media(min-width: map-get($grid-breakpoints, sm)) {
    padding-bottom: 64px;
  }

  .features_list {
    margin-bottom: 24px;
    .feature {
      display: flex;
      padding: 10px 0;
      align-items: center;
      justify-content: space-between;
      .feature_image {
        flex-basis: 0;
        flex-grow: 1;
        .image_wrapper {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          background-color: #F1F1FC;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-left: auto;
          margin-right: 24px;
          img {
            max-width: 60px;
            max-height: 60px;
            border-radius: 30px
          }
        }
      }
      .feature_information {
        flex-basis: 10px;
        flex-grow: 3;
        padding-right: 6px;
        .feature_title {
          font-size: 14px;
          font-weight: bold;
          color: #686A79;
        }
        .feature_subtitle {
          font-size: 14px;
          font-weight: var(--font-regular);
          color: #686A79;
          margin-top: 6px;
        }
      }
    }
  }
}
