@import '../../../styles/base/layout';


.rate_group {
  width: 100%;
  .rate_item {
    width: 100%;
    font-size: 12px;
    @media(min-width: map-get($grid-breakpoints, lg)) {
      font-size: 14px;
    }
    &.rate_item__heading {
      text-align: left;
      th {
        font-weight: bold;
        border: solid 1px #bbb;
        padding: 4px 12px;
      }
    }
    .description_column {
      width: 70%;
    }
    .pricing_column {
      width: 30%;
    }
    .pricing {
      text-align: center;
      .pricing__tax_inclusion {
        display: block;
        @media(min-width: map-get($grid-breakpoints, lg)) {
          display: initial;
        }
      }
    }
    td {
      border: solid 1px #bbb;
      padding: 4px 12px;
    }
  }
}
