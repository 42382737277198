@import '../../../styles/base/layout';

.rate_card {
  margin: 8px;
  padding: 0 8px;
  @media(min-width: map-get($grid-breakpoints, lg)) {
    padding: 0 24px;
    margin: 32px 24px 40px;
  }
  overflow: scroll;
  .title {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
    margin-top: 10px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: solid 1px #bbb;
    h3 {
      margin: 0;
    }
  }
  .fine_text {
    @media(min-width: map-get($grid-breakpoints, lg)) {
      text-align: right;
    }
    font-size: 12px;
    color: #666;
    padding: 12px 0;
    span {
      position: relative;
      top: -2px;
      font-weight: bold;
    }
  }
  .rate_groups_container {
    .error {
      font-size: 20px;
      font-weight: 500;
      color: var(--font-color-netural);
    }
  }
  .loading {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: var(--font-color-netural);
  }
  &.app_render{
    padding-top: 50px;
    .title{
      position: fixed;
      border-bottom: 0px;
      top: 0;
      width: 100%;
      margin-top: 0;
      margin-bottom: 8px;
      margin-right: 12px;
      padding-top: 24px;
    }
  }
}
