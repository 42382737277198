@import '../../../styles/base/layout';


.product_rating {
  display: flex;
  margin: 0;
  .star {
    width: 8px;
    height: 8px;
    &.full_star {
      background: url(../../../public/static/images/PremiumCards/full_star.svg) no-repeat center;
  		background-size: cover;
    }
    &.half_star {
      background: url(../../../public/static/images/PremiumCards/half_star.svg) no-repeat center;
  		background-size: cover;
    }
    &.empty_star {
      background: url(../../../public/static/images/PremiumCards/empty_star.svg) no-repeat center;
  		background-size: cover;
    }
  }
  .rating_text {
    margin-left: 8px;
    font-size: 12px;
    color: #686A79;
  }
}
