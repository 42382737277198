@import '../../../styles/base/layout';


.image_carousel {
  position: relative;
  margin-bottom: 16px;
  .container {
    padding: 0 10px;
    .slide {
      margin-bottom: -3px;
      img {
        width: 100%;
      }
    }
  }
  .watermark {
    position: absolute;
    width: 64px;
    height: 24px;
    opacity: 0.8;
    right: 32px;
    bottom: 22px;
  }
}
