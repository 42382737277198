@import '../../../styles/base/layout';

.video_player {
  padding: 0 10px;
  position: relative;
  video {
    background-color: #000;
  }
  .overlay {
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    color: #fff;
    font-weight: bold;
    height:calc(100% - 3px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .replayButton {
    width: 20px;
    height: 20px;
    background: url(../../../public/static/images/PremiumCards/replay.svg) no-repeat center;
    background-size: cover;
    margin: 0 auto;
  }
  .watermark {
    position: absolute;
    width: 64px;
    height: 24px;
    opacity: 0.8;
    right: 32px;
    bottom: 22px;
  }
}

.video_player__wrapper {
  margin-bottom: -3px;
}
